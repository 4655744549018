<template>
  <div class="pc">
    <v-card
      class="pa-0 mx-auto"
      width="100%"
      max-width="1200px"
      min-width="160px"
      color="transparent"
      elevation="0"
    >
      <v-app-bar height="78px" elevation="0" class="transparent">
        <v-spacer></v-spacer>
        <v-btn class="common-text" text @click="firstPageBtnClick">首页</v-btn>
        <v-avatar>
          <v-img
            :src="resHost + headImg"
            max-width="30px"
            max-height="30px"
          ></v-img>
        </v-avatar>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div>
              <v-btn
                text
                class="my-auto pa-0 ml-n2 comment--text font-weight-medium f16"
              >
                <v-clamp class="mr-1" style="max-width:88px" :max-lines="1">{{
                  name
                }}</v-clamp>
              </v-btn>
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
                class="my-auto primary--text f16 pl-0 pr-3"
              >
                {{ isTeacher ? "[老师]" : "[学生]" }}
              </v-btn>
            </div>
          </template>
          <div v-if="isShowMenu && isTeacher">
            <div class="triangle-up"></div>
            <v-btn
              dark
              class="f16"
              @click="changePassBtnClickHandler"
              color="#474849"
              height="50px"
              >修改密码</v-btn
            >
          </div>
        </v-menu>

        <v-clamp class="my-auto line">|</v-clamp>
        <v-btn
          text
          class="my-auto  primary--text pr-0 pl-3 f16"
          @click="exitBtnClickHandler"
          >退出登录</v-btn
        >
      </v-app-bar>
    </v-card>
    <slot></slot>
  </div>
</template>

<script>
import UserDataMixins from "@/views/pc/index/_mixins/pc-user-mixins.js";
import VClamp from "vue-clamp";
import store from "@/store";
export default {
  mixins: [UserDataMixins],
  props: {
    isShowMenu: {
      type: Boolean,
      default: true,
    },
  },
  activated() {
    this.getUserInfo();
  },
  methods: {
    exitBtnClickHandler() {
      this.clearUserInfo();
      window.localStorage.removeItem("jwt");
      store.commit("auth/clear");
      this.$router.push({
        name: "pcLogin",
      });
    },
    firstPageBtnClick() {
      this.$router.push({
        name: "pcReaderIndex",
      });
    },
    changePassBtnClickHandler() {
      this.$router.push({
        name: "tecModifyPsd",
      });
    },
  },
  components: {
    VClamp,
  },
};
</script>

<style lang="scss" scoped>
.pc {
  width: 100%;
}

.line {
  color: #c9c9c9;
}

.triangle-up {
  background: transparent;
  width: 0;
  height: 0;
  margin-left: 58%;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #474849;

  // background: #474849;
  // box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.12);
}

.v-menu__content {
  box-shadow: none !important;
}
</style>
