import { mapActions, mapState } from "vuex";
export default {
    methods: {
        ...mapActions({
            getUserInfo: "userInfo/getUserInfo",
            clearUserInfo: "userInfo/clearUserInfo"
        }),
    },
    computed: {
        ...mapState({
            headImg: (state) => state.userInfo.headImg,
            name: (state) => state.userInfo.name,
            grade: (state) => state.userInfo.class.grade,
            schoolId: (state) => state.userInfo.school.id,
            isTeacher: (state) => {
                if (state.userInfo.account) {
                    return true
                } else {
                    return false
                }
            },
            account: (state) => state.userInfo.account,
        }),
    },
};
